<template>
  <div>
    <workspaceEnv workspaceId="GLOBAL" />
  </div>
</template>
<script>
import workspaceEnv from "./workspace-env.vue";

export default {
  components: {
    workspaceEnv,
  },
};
</script>
